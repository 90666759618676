@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);

  &__circle {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    width: 116px;
    height: 116px;
    border-radius: 100px;

    background-color: #3fae2a;

    &::after {
      content: "";
      position: absolute;
      top: 10px;
      left: 10px;

      width: 94px;
      height: 94px;
      border: 1px dashed #fff;
      border-radius: 100px;

      animation: rotating 6s linear infinite;
    }
  }

  &__icon {
    width: auto;
    height: 68px;
  }
}
