.app {
  &__header {
    position: sticky;
    z-index: 999;
    width: 100%;
    padding: 0;
    border-bottom: 1px solid #ddd;
    height: auto;

    &_group {
      display: flex;
      justify-content: center;
      padding: 0 20px 0 0;
      background-color: #0378d6;
      line-height: 48px;
      height: 4rem;
    }

    .ant-menu {
      height: 48px;
      line-height: 48px;
    }
    .logo-text {
      padding-left: 24px;

      font-size: 18px;
      font-weight: bold;
      color: #333;
    }

    .user-info {
      position: absolute;
      top: 0px;
      right: 2%;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #333333;
      cursor: pointer;

      label {
        margin: 0 5px 0 10px;
        cursor: pointer;
        font-size: 15px;
      }

      svg {
        font-size: 12px;
      }
    }

    .header__group-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .powered-by {
        color: #fff;
        font-weight: 600;

        position: absolute;
        top: -10px;
        font-size: 14px;
        white-space: nowrap;
      }

      img {
        max-height: 36px;
      }

      > div {
        height: 48px;
        padding-top: 7px;
        img {
          max-height: 18px;
        }
      }
    }
  }
}

.profile {
  $self: &;
  padding: 13px 16px 13px;
  position: relative;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: solid 1px transparent;
  border-bottom: none;
  transition: all 0.2s cubic-bezier(0.755, 0.05, 0.855, 0.06);

  .ant-avatar > img {
    object-fit: unset;
  }

  &--left {
    #{ $self }__name {
      color: #000000 !important;
    }

    .chevron {
      color: #000000 !important;
    }
  }

  &--active {
    background: #ffffff;
    border-color: #cccccc;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);

    #{ $self }__name {
      color: #0279d7;
    }

    .chevron {
      color: #0279d7;
      transform: rotate(180deg);
    }
  }

  &-menu {
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    line-height: 1.15;
    margin-top: -5px;
    border: solid 1px #cccccc;
    border-top: none;
    box-shadow: 0 10px 9px 0 rgba(0, 0, 0, 0.3);
    padding: 0;
    padding-bottom: 4px;

    &__divider {
      margin: 0px 16px 8px;
      padding: 0 !important;
      color: transparent !important;
    }

    li {
      padding: 8px 16px;
      color: #808080;
      font-size: 13px;
    }
  }

  &-modal {
    .ant-avatar > img {
      object-fit: unset;
    }

    &__inner {
      border-radius: 4px;
      border: solid 1px #e9ecef;
      background-color: #f9f9f9;
      padding: 20px;
      display: grid;
      grid-template-columns: 70fr 30fr;
      gap: 52px;
    }

    &__file-actions {
      align-self: flex-end;

      .ant-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-bottom: 5px;
        color: #808080;
        height: auto;

        &[disabled] {
          opacity: 0.5;
        }

        &:last-child {
          margin-bottom: 0px;
        }

        svg {
          margin-right: 6px;
        }
      }
    }

    &__note {
      .ant-form-item-control-input-content {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
      }

      .ant-avatar {
        background-color: transparent;
      }

      i {
        color: #a1a1a1;
        margin-left: 4px;
      }
    }

    .ant-modal {
      &-content {
        button:disabled {
          opacity: 0.5;
        }
      }

      &-title {
        font-size: 18px;
        font-weight: bold;
        color: #3b4245;
      }

      &-header {
        padding: 20px;
        border-bottom: none;
        border-radius: 4px 4px 0 0;
      }

      &-body {
        padding: 0 20px;

        .ant-form-item-control-input-content {
          input {
            height: 44px;
          }

          input:disabled {
            background-color: #dbdbdb;
          }
        }
      }

      &-footer {
        padding: 20px;
        border-radius: 0px 0px 4px 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        .ant-btn {
          min-width: 170px;
          height: 40px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          border-radius: 1px;

          &--gray-outline {
            border: solid 1px #a1a1a1;
            color: #a1a1a1;
          }

          &--primary {
            color: #ffffff;
            background-color: #007dc5;
          }
        }
      }
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 8px;
    align-items: center;
  }

  &__name {
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.15;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-avatar-square {
    border-radius: 4px;
  }

  svg {
    transition: transform 0.4s;
    color: #ffffff;
  }
}

.unselect {
  opacity: 0.5;
  &:hover {
    opacity: unset;
  }
}
