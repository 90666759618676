.app {
  min-height: 100vh;

  &--has-footer {
    min-height: 0;
    height: calc(100vh - 36px);
    overflow: auto;
  }

  .layout {
    &__content {
      padding: 20px;
      margin: 0;
    }
  }
}
.timezone {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  background: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  line-height: 1.15;
  font-size: 14px;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-weight: 500;
    color: #3b4245;
    margin-left: 10px;
  }

  &__tooltip {
    & .ant-tooltip-inner {
      padding: 12px 10px;
    }
  }
}
.custom-ant-message-warning {
  .ant-message-notice-content {
    padding: 12px 20px;
    border-radius: 4px;
    border: solid 1px #ffcd00;
    background-color: #fff9e1;
    .ant-message-warning {
      .anticon {
        display: none;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        text-align: center;
        color: #282828;
      }
    }
  }
}
