body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.w100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.w-50 {
  width: 50%;
}
.align-center {
  align-items: center;
}

.ant-dropdown-menu-item {
}
.ant-dropdown-menu-item:hover {
  background-color: rgb(225, 240, 248);
}

.relative {
  position: relative;
}

.ml5 {
  margin-left: 5px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.mb20 {
  margin-bottom: 20px;
}
.short-text {
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cur-pointer {
  cursor: pointer;
}
.input-italic::-webkit-input-placeholder {
  font-style: italic;
}

.input-italic:-moz-placeholder {
  font-style: italic;
}

.input-italic::-moz-placeholder {
  font-style: italic;
}

.input-italic:-ms-input-placeholder {
  font-style: italic;
}

.table-header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sort-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
  }
  .unsort-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
  }
  .icon {
    color: white;
  }
  .icon:hover {
    color: yellow;
  }
}
.zoom-90 {
  zoom: 90%;
}

.ant-popover-inner-content {
  padding: 0;
}

.table-common {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.07);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  img {
    width: 15px;
    margin-bottom: 2px;
    margin-right: 5px;
  }

  thead {
    th {
      padding: 5px 10px;

      color: #ffffff;
      font-weight: bold;
      .ant-table-column-sorters {
        justify-content: space-between !important;
        width: 100%;
      }
      &.booking-block {
        background-color: #025ba1;
        &:hover {
          background-color: #025ba1 !important;
        }
      }
      &.pickup-block {
        background-color: #0378d6;
        &:hover {
          background-color: #0378d6 !important;
        }
      }
      &.next-des-block {
        background-color: #3b94dc;
        &:hover {
          background-color: #3b94dc !important;
        }
      }
      &.final-destination-block {
        background-color: #80bbeb;
        &:hover {
          background-color: #80bbeb !important;
        }
      }
      &.ant-table-cell.ant-table-cell-scrollbar {
        background-color: #80bbeb;
        box-shadow: none !important;
      }
    }
  }

  tbody {
    color: black;
    tr {
      // &:nth-child(even) {
      //   background-color: #f9f9f9;
      // }

      td {
        vertical-align: middle;
        &.booking-block {
          cursor: pointer;
          .booking-block-link:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  tr:hover {
    td {
      background-color: unset !important;
    }
  }
}

.last-col {
  border-right: none !important;
}

.ant-select-item-group {
  min-height: 1px;
  padding: 0;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #e1f0f8;
  font-weight: normal;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: normal;
}

.ant-select-clear:hover {
  color: #f1453d;
}

.globalLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
